export type LanguageText = {
    createGroup: {
        createAGroup: string;
        createGroup: string;
        labels: {
            groupName: string;
        },
        tooltips: {
            groupName: string;
        },
        toasts: {
            createdSuccessfully: string;
            creationError: string;
        },
        confirm: {
            title: string;
            phrase1: string;
            phrase2: string;
            phrase3: string;
            buttons: {
                create: string;
                cancel: string;
            }
        }
    }
    groupInvite: {
        inviteUser: string;
        email: string;
        buttons: {
            admin: string;
            member: string;
            invite: string;
            cancel: string;
        }
        toasts: {
            invited: string;
        }

    }
    groupTable: {
        groups: string;
        groupName: string;
        tableElements: {
            quickEdit: string;
            searchAllColumns: string;
            goToPage: string;
            show: string;
            noGroupsFound: string;
        }
    }
    joinGroup: {
        phrase1: string;
        phrase2: string;
        phrase3: string;
        error: {
            thereWasAProblem: string;
            ifTheProblemPersists: string;
            pleaseClickEmailLink: string;
        }
        modalElements: {
            title: string;
            accept: string;
            cancel: string;
        }
        toast: {
            joined: string;
            successfully: string;
        }
    }
    viewEditGroup: {
        editGroup: string;
        labels: {
            groupName: string;
            members: string;
            devices: string;
        }
        buttons: {
            save: string;
            cancel: string;
            invite: string;
            leaveGroup: string;
            deleteGroup: string;
        }
        tabs: {
            overview: string;
            members: string;
            devices: string;
            dashboards: string;
            subscription: string;
        }
        toasts: {
            updated: string;
            deleted: string;
            left: string;
        }
        modals: {
            leaveModal: {
                title: string;
                body: {
                    phrase1: string;
                    phrase2: string;
                }
                buttons: {
                    accept: string;
                    cancel: string;
                }
            }
            deleteModal: {
                title: string;
                body: {
                    phrase1: React.ReactNode;
                    phrase2: React.ReactNode;
                }
                buttons: {
                    accept: string;
                    cancel: string;
                }
                labels: {
                    password: string;
                }
            }
        }
    }
    memberTable: {
        members: string;
        tableElements: {
            headers: {
                userName: string;
                memberID: string;
                joinDate: string;
            }
            quickEdit: string;
            searchAllColumns: string;
            goToPage: string;
            show: string;
            loading: string;
            noMembersFound: string;
        },
        toast: {
            cantEditOwnPerms: string;
        }
    }
    inviteTable: {
        outstandingInvitations: string;
        tableElements: {
            headers: {
                email: string;
                invitedBy: string;
                inviteDate: string;
                cancelInvitation: string;
            }
            noInvitesFound: string;
        }
        toast: {
            cancelSuccess: string;
            cancelError: string;
            updateSuccess: string;
            updateError: string;
        }
        modals: {
            cancelInvite: {
                title: string;
                body: {
                    phrase1: string;
                    thisUser: string;
                    phrase2: string;
                    phrase3: string;
                }
                buttons: {
                    accept: string;
                    cancel: string;
                }
            }
        }
    }
    groupEditUser: {
        toasts: {
            permsEdited: string;
            removed: string;
        }
        modals: {
            removeUser: {
                title: string;
                body: {
                    phrase1: string;
                    thisUser: string;
                    phrase2: string;
                    thisGroup: string;
                    phrase3: string;
                }
                buttons: {
                    accept: string;
                    cancel: string;
                },
            },
            transferOwner: {
                title: {
                    phrase1: string;
                    phrase2: string;
                },
                body: {
                    phrase1: string;
                    thisGroup: string;
                    phrase2: string;
                    thisUser: string;
                    phrase3: string;
                },
                labels: {
                    password: string;
                },
                buttons: {
                    accept: string;
                    cancel: string;
                },
            },
            editPerms: {
                title: {
                    phrase1: string;
                    thisUser: string;
                    phrase2: string;
                };
                buttons: {
                    makeOwner: {
                        phrase1: string;
                        thisUser: string;
                        phrase2: string;
                    }
                    remove: string;
                    accept: string;
                    cancel: string;
                }
            }
        }
    }
};
export const English: LanguageText = {
    createGroup: {
        createAGroup: "Create A Group",
        createGroup: "Create Group",
        labels: {
            groupName: "Group Name",
        },
        tooltips: {
            groupName: "Auto fill this value by clicking the link in the email you received"
        },
        toasts: {
            createdSuccessfully: "Group created successfully",
            creationError: "There was an error creating the group",
        },
        confirm: {
            title: "Create Group",
            phrase1: "Are you sure you, ",
            phrase2: ", would like to create a group called ",
            phrase3: "?",
            buttons: {
                create: "Create",
                cancel: "Cancel",
            }
        }
    },
    groupInvite: {
        inviteUser: "Invite User",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Member",
            invite: "Invite",
            cancel: "Cancel"
        },
        toasts: {
            invited: "User invited successfully",
        }
    },
    groupTable: {
        groups: "Groups",
        groupName: "Group Name",
        tableElements: {
            quickEdit: "Quick Edit",
            searchAllColumns: "Search all Columns",
            goToPage: "Go to page",
            show: "Show",
            noGroupsFound: "No groups found",
        }
    },
    joinGroup: {
        phrase1: "Are you sure you",
        phrase2: "would like to join",
        phrase3: "?",
        error: {
            thereWasAProblem: "There was an error with the group you are trying to join. Please click the link in the email again.",
            ifTheProblemPersists: "If the problem persists, please contact the group admin or",
            pleaseClickEmailLink: "Please click on the link sent to your email to join the group."
        },
        modalElements: {
            title: "Join Group",
            accept: "Join",
            cancel: "Logout"
        },
        toast: {
            joined: "Joined",
            successfully: "successfully",
        }
    },
    viewEditGroup: {
        editGroup: "Edit Group",
        labels: {
            groupName: "Group Name",
            members: "Members",
            devices: "Devices"
        },
        buttons: {
            save: "Save",
            cancel: "Cancel",
            invite: "Invite",
            leaveGroup: "Leave Group",
            deleteGroup: "Delete Group",
        },
        tabs: {
            overview: "Overview",
            members: "Members",
            devices: "Devices",
            dashboards: "Dashboards",
            subscription: "Subscription",
        },
        toasts: {
            updated: "Group updated.",
            deleted: "Group deleted.",
            left: "Left group.",
        },
        modals: {
            leaveModal: {
                title: "Leave Group",
                body: {
                    phrase1: ", are you sure you would like to leave ",
                    phrase2: "?",
                },
                buttons: {
                    accept: "Leave Group",
                    cancel: "Cancel",
                }
            },
            deleteModal: {
                title: "Delete Group",
                body: {
                    phrase1: <>Are you sure that you want to <b>delete</b></>,
                    phrase2: "? This action cannot be undone.",
                },
                buttons: {
                    accept: "Delete Group",
                    cancel: "Cancel",
                },
                labels: {
                    password: "Password",
                }
            }
        }
    },
    memberTable: {
        members: "Members",
        tableElements: {
            headers: {
                userName: "User Name",
                memberID: "Member ID",
                joinDate: "Join Date"
            },
            quickEdit: "Quick Edit",
            searchAllColumns: "Search All Columns",
            goToPage: "Go to page",
            show: "Show",
            loading: "Loading",
            noMembersFound: "No members found"
        },
        toast: {
            cantEditOwnPerms: "You aren't able to edit your own permissions"
        }
    },
    inviteTable: {
        outstandingInvitations: "Outstanding Invitations",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Invited By",
                inviteDate: "Invite Date",
                cancelInvitation: "Cancel Invitation"
            },
            noInvitesFound: "No invitations found"
        },
        toast: {
            cancelSuccess: "Invitation successfully cancelled.",
            cancelError: "Problem cancelling invitation. Please try again later.",
            updateSuccess: "Invitation permissions successfully updated.",
            updateError: "Problem updating invitation permissions. Please try again later."
        },
        modals: {
            cancelInvite: {
                title: "Cancel Invitation",
                body: {
                    phrase1: "Are you sure you want to cancel invites to ",
                    thisUser: "this user",
                    phrase2: "? This will remove all invites to ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Cancel Invite(s)",
                    cancel: "Cancel"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "User permissions updated",
            removed: "User removed from group"
        },
        modals: {
            removeUser: {
                title: "Remove User",
                body: {
                    phrase1: "Remove",
                    thisUser: "this user",
                    phrase2: "from",
                    thisGroup: "this group",
                    phrase3: "?",
                },
                buttons: {
                    accept: "Remove",
                    cancel: "Cancel"
                },
            },
            transferOwner: {
                title: {
                    phrase1: "Transfer Ownership to",
                    phrase2: "",
                },
                body: {
                    phrase1: "Transfer ownership of",
                    thisGroup: "this group",
                    phrase2: "to",
                    thisUser: "this user",
                    phrase3: "? You will no longer have group ownership. Account password required to continue.",
                },
                labels: {
                    password: "Password"
                },
                buttons: {
                    accept: "Transfer",
                    cancel: "Cancel"
                },
            },
            editPerms: {
                title: {
                    phrase1: "Edit Member",
                    thisUser: "this user",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Make",
                        thisUser: "this user",
                        phrase2: "group owner"
                    },
                    remove: "Remove",
                    accept: "Save",
                    cancel: "Cancel"
                },
            }

        }
    }
}

export const French: LanguageText = {
    createGroup: {
        createAGroup: "Créer un groupe",
        createGroup: "Créer un groupe",
        labels: {
            groupName: "Nom du groupe",
        },
        tooltips: {
            groupName: "Remplissez automatiquement cette valeur en cliquant sur le lien dans l'email que vous avez reçu",
        },
        toasts: {
            createdSuccessfully: "Groupe créé avec succès",
            creationError: "Il y a eu une erreur en créant le groupe",
        },
        confirm: {
            title: "Créer un groupe",
            phrase1: "Êtes-vous sûr que vous, ",
            phrase2: ", voulez créer un groupe appelé ",
            phrase3: "?",
            buttons: {
                create: "Créer",
                cancel: "Annuler",
            }
        }
    },
    groupInvite: {
        inviteUser: "Inviter un utilisateur",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Membre",
            invite: "Invite",
            cancel: "Annuler"
        },
        toasts: {
            invited: "Utilisateur invité avec succès",
        }
    },
    groupTable: {
        groups: "Groupes",
        groupName: "Nom du groupe",
        tableElements: {
            quickEdit: "Édition rapide",
            searchAllColumns: "Rechercher dans toutes les colonnes",
            goToPage: "Aller à la page",
            show: "Afficher",
            noGroupsFound: "Aucun groupe trouvé",
        }
    },
    joinGroup: {
        phrase1: "Êtes-vous sûr que vous, ",
        phrase2: ", voulez rejoindre ",
        phrase3: "?",
        error: {
            thereWasAProblem: "Il y a eu une erreur avec le groupe que vous essayez de rejoindre. Veuillez cliquer à nouveau sur le lien dans l'e-mail.",
            ifTheProblemPersists: "Si le problème persiste, veuillez contacter l'administrateur du groupe ou",
            pleaseClickEmailLink: "Veuillez cliquer sur le lien envoyé à votre e-mail pour rejoindre le groupe."
        },
        modalElements: {
            title: "Rejoindre le groupe",
            accept: "Rejoindre",
            cancel: "Déconnexion"
        },
        toast: {
            joined: "Rejoint",
            successfully: "avec succès",
        }
    },
    viewEditGroup: {
        editGroup: "Modifier le groupe",
        labels: {
            groupName: "Nom du groupe",
            members: "Membres",
            devices: "Dispositifs"
        },
        buttons: {
            save: "Sauvegarder",
            cancel: "Annuler",
            invite: "Invite",
            leaveGroup: "Groupe de congé",
            deleteGroup: "Supprimer le groupe",
        },
        tabs: {
            overview: "Aperçu",
            members: "Membres",
            devices: "Dispositifs",
            dashboards: "Tableaux de bord",
            subscription: "Abonnement",
        },
        toasts: {
            updated: "Groupe mis à jour",
            deleted: "Groupe supprimé.",
            left: "Groupe de gauche.",
        },
        modals: {
            leaveModal: {
                title: "Groupe de congé",
                body: {
                    phrase1: ", êtes-vous sûr de vouloir quitter ",
                    phrase2: "?",
                },
                buttons: {
                    accept: "Groupe de congé",
                    cancel: "Annuler",
                }
            },
            deleteModal: {
                title: "Supprimer le groupe",
                body: {
                    phrase1: <>Êtes-vous sûr de vouloir <b>supprimer</b></>,
                    phrase2: "? Cette action ne peut être annulée.",
                },
                buttons: {
                    accept: "Supprimer le groupe",
                    cancel: "Annuler",
                },
                labels: {
                    password: "Mot de passe",
                },
            }
        }
    },
    memberTable: {
        members: "Membres",
        tableElements: {
            headers: {
                userName: "Nom d'utilisateur",
                memberID: "ID du membre",
                joinDate: "Date d'adhésion"
            },
            quickEdit: "Édition rapide",
            searchAllColumns: "Rechercher dans toutes les colonnes",
            goToPage: "Aller à la page",
            show: "Afficher",
            loading: "Chargement",
            noMembersFound: "Aucun membre trouvé"
        },
        toast: {
            cantEditOwnPerms: "Vous n'êtes pas en mesure de modifier vos propres permissions"
        }
    },
    inviteTable: {
        outstandingInvitations: "Invitations sans réponse",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Invité par",
                inviteDate: "Date de l'invitation",
                cancelInvitation: "Annuler l'invitation"
            },
            noInvitesFound: "Aucune invitation trouvée"
        },
        toast: {
            cancelSuccess: "Invitation annulée avec succès.",
            cancelError: "Problème d'annulation d'invitation. Veuillez réessayer plus tard.",
            updateSuccess: "",
            updateError: ""
        },
        modals: {
            cancelInvite: {
                title: "Annuler l'invitation",
                body: {
                    phrase1: "Êtes-vous sûr de vouloir annuler les invitations à ",
                    thisUser: "cet utilisateur",
                    phrase2: "? Cela supprimera toutes les invitations à ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Annuler l'invitation (les invitations)",
                    cancel: "Annuler"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Permissions d'utilisateur mises à jour",
            removed: "Utilisateur retiré du groupe"
        },
        modals: {
            removeUser: {
                title: "Supprimer l'utilisateur",
                body: {
                    phrase1: "Supprimer ",
                    thisUser: "cet utilisateur",
                    phrase2: "du",
                    thisGroup: "ce groupe",
                    phrase3: "?"
                },
                buttons: {
                    accept: "Supprimer",
                    cancel: "Annuler"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Transférer la propriété à ",
                    phrase2: "",
                },
                body: {
                    phrase1: "Transférer la propriété de ",
                    thisGroup: "ce groupe",
                    phrase2: "à",
                    thisUser: "cet utilisateur",
                    phrase3: "? Vous ne serez plus propriétaire du groupe. Le mot de passe du compte est requis pour continuer."
                },
                labels: {
                    password: "Mot de passe"
                },
                buttons: {
                    accept: "Transférer",
                    cancel: "Annuler"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Modifier le membre",
                    thisUser: "Cet utilisateur",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Faire de ",
                        thisUser: "cet utilisateur",
                        phrase2: "le propriétaire du groupe"
                    },
                    remove: "Supprimer",
                    accept: "Sauvegarder",
                    cancel: "Annuler"
                }
            }
        }
    }
};

export const German: LanguageText = {
    createGroup: {
        createAGroup: "Eine Gruppe erstellen",
        createGroup: "Gruppe erstellen",
        labels: {
            groupName: "Gruppenname",
        },
        tooltips: {
            groupName: "Füllen Sie diesen Wert automatisch aus, indem Sie auf den Link in der E-Mail klicken, die Sie erhalten haben",
        },
        toasts: {
            createdSuccessfully: "Gruppe erfolgreich erstellt",
            creationError: "Es ist ein Fehler bei der Erstellung der Gruppe aufgetreten",
        },
        confirm: {
            title: "Gruppe erstellen",
            phrase1: "Sind Sie sicher, dass Sie, ",
            phrase2: ", eine Gruppe namens ",
            phrase3: " erstellen möchten?",
            buttons: {
                create: "Erstellen",
                cancel: "Abbrechen",
            }
        }
    },
    groupInvite: {
        inviteUser: "Benutzer einladen",
        email: "E-Mail",
        buttons: {
            admin: "Verwaltung",
            member: "Mitglied",
            invite: "Einladen",
            cancel: "Abbrechen"
        },
        toasts: {
            invited: "Benutzer erfolgreich eingeladen",
        }
    },
    groupTable: {
        groups: "Gruppen",
        groupName: "Gruppenname",
        tableElements: {
            quickEdit: "Schnellbearbeitung",
            searchAllColumns: "Alle Spalten durchsuchen",
            goToPage: "Weiter zur Seite",
            show: "Anzeigen",
            noGroupsFound: "Keine Gruppen gefunden",
        }
    },
    joinGroup: {
        phrase1: "Sind Sie sicher, dass Sie, ",
        phrase2: ", der Gruppe ",
        phrase3: " beitreten möchten?",
        error: {
            thereWasAProblem: "Bei der Gruppe, der Sie beitreten möchten, ist ein Fehler aufgetreten. Bitte klicken Sie erneut auf den Link in der E-Mail.",
            ifTheProblemPersists: "Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Gruppenadministrator oder",
            pleaseClickEmailLink: "Bitte klicken Sie auf den Link in Ihrer E-Mail, um der Gruppe beizutreten",
        },
        modalElements: {
            title: "Gruppe beitreten",
            accept: "Beitreten",
            cancel: "Abmelden"
        },
        toast: {
            joined: "Verbunden",
            successfully: "erfolgreich",
        }
    },
    viewEditGroup: {
        editGroup: "Gruppe bearbeiten",
        labels: {
            groupName: "Gruppenname",
            members: "Mitglieder",
            devices: "Geräte"
        },
        buttons: {
            save: "Speichern",
            cancel: "Abbrechen",
            invite: "Einladen",
            leaveGroup: "Gruppe verlassen",
            deleteGroup: "Gruppe löschen",
        },
        tabs: {
            overview: "Überblick",
            members: "Mitglieder",
            devices: "Geräte",
            dashboards: "Dashboards",
            subscription: "Abonnement",
        },
        toasts: {
            updated: "Gruppe aktualisiert",
            deleted: "Gruppe gelöscht",
            left: "Gruppe verlassen",
        },
        modals: {
            leaveModal: {
                title: "Gruppe verlassen",
                body: {
                    phrase1: ", Sind sie Sicher, dass Sie ",
                    phrase2: " verlassen möchten?",
                },
                buttons: {
                    accept: "Gruppe verlassen",
                    cancel: "Abbrechen",
                }
            },
            deleteModal: {
                title: "Gruppe löschen",
                body: {
                    phrase1: "Sind Sie sicher, dass Sie die Gruppe ",
                    phrase2: <><b> löschen</b> möchten? Dies kann nicht rückgängig gemacht werden.</>,
                },
                buttons: {
                    accept: "Gruppe löschen",
                    cancel: "Abbrechen",
                },
                labels: {
                    password: "Passwort",
                },
            }
        }
    },
    memberTable: {
        members: "Mitglieder",
        tableElements: {
            headers: {
                userName: "Nutzername",
                memberID: "Mitglieds-ID",
                joinDate: "Beitrittsdatum"
            },
            quickEdit: "Schnellbearbeitung",
            searchAllColumns: "Alle Spalten durchsuchen",
            goToPage: "Weiter zur Seite",
            show: "Anzeigen",
            loading: "Laden",
            noMembersFound: "Keine Mitglieder gefunden"
        },
        toast: {
            cantEditOwnPerms: "Sie können Ihre eigenen Berechtigungen nicht bearbeiten"
        }
    },
    inviteTable: {
        outstandingInvitations: "Unbeantwortete Einladungen",
        tableElements: {
            headers: {
                email: "E-Mail",
                invitedBy: "Eingeladen von",
                inviteDate: "Einladungsdatum",
                cancelInvitation: "Einladung stornieren"
            },
            noInvitesFound: "Keine Einladungen gefunden"
        },
        toast: {
            cancelSuccess: "Einladung erfolgreich storniert",
            cancelError: "Problem bei der Stornierung der Einladung. Bitte versuchen Sie es später noch einmal.",
            updateSuccess: "Einladung erfolgreich aktualisiert",
            updateError: "Problem bei der Aktualisierung der Einladung. Bitte versuchen Sie es später noch einmal.",
        },
        modals: {
            cancelInvite: {
                title: "Einladung stornieren",
                body: {
                    phrase1: "Sind Sie sicher, dass Sie die Einladungen zu ",
                    thisUser: "dieser Benutzer",
                    phrase2: " stornieren möchten? Dies entfernt alle Einladungen an ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Einladung(en) stornieren",
                    cancel: "Abbrechen"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Benutzerberechtigungen aktualisiert",
            removed: "Benutzer aus Gruppe entfernt"
        },
        modals: {
            removeUser: {
                title: "Benutzer entfernen",
                body: {
                    phrase1: "",
                    thisUser: "dieser Benutzer",
                    phrase2: "aus",
                    thisGroup: "diese Gruppe",
                    phrase3: "entfernen?"
                },
                buttons: {
                    accept: "Entfernen",
                    cancel: "Abbrechen"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Eigentümerschaft an",
                    phrase2: "übertragen"
                },
                body: {
                    phrase1: "Eigentum an",
                    thisGroup: "dieser Gruppe",
                    phrase2: "auf",
                    thisUser: "diesen Benutzer",
                    phrase3: "übertragen? Sie sind dann nicht mehr Eigentümer der Gruppe. Kontopasswort erforderlich, um fortzufahren."
                },
                labels: {
                    password: "Kennwort"
                },
                buttons: {
                    accept: "Übertragen",
                    cancel: "Abbrechen"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Mitglied",
                    thisUser: "diesen Benutzer",
                    phrase2: "bearbeiten"
                },
                buttons: {
                    makeOwner: {
                        phrase1: "",
                        thisUser: "Dieser Benutzer",
                        phrase2: "zum Gruppenbesitzer befördern"
                    },
                    remove: "Entfernen",
                    accept: "Speichern",
                    cancel: "Abbrechen"
                }
            }
        }
    }
};
export const Dutch: LanguageText = {
    createGroup: {
        createAGroup: "Creëer een groep",
        createGroup: "Groep creëren",
        labels: {
            groupName: "Groepsnaam",
        },
        tooltips: {
            groupName: "Vul deze waarde automatisch in door te klikken op de link in de e-mail die u hebt ontvangen."
        },
        toasts: {
            createdSuccessfully: "Groep succesvol aangemaakt",
            creationError: "Er is een fout opgetreden bij het aanmaken van de groep",
        },
        confirm: {
            title: "Groep creëren",
            phrase1: "Weet je zeker dat jij, ",
            phrase2: ", een groep wilt aanmaken met de naam ",
            phrase3: "?",
            buttons: {
                create: "Maak",
                cancel: "Annuleren",
            }
        }
    },
    groupInvite: {
        inviteUser: "Gebruiker uitnodigen",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Lid",
            invite: "Uitnodigen",
            cancel: "Annuleren"
        },
        toasts: {
            invited: "Gebruiker succesvol uitgenodigd",
        }
    },
    groupTable: {
        groups: "Groepen",
        groupName: "Groepsnaam",
        tableElements: {
            quickEdit: "Snelle bewerking",
            searchAllColumns: "Doorzoek alle kolommen",
            goToPage: "Ga naar de pagina",
            show: "Toon",
            noGroupsFound: "Geen groepen gevonden",
        }
    },
    joinGroup: {
        phrase1: "Weet je zeker dat je, ",
        phrase2: ", lid wilt worden van ",
        phrase3: "?",
        error: {
            thereWasAProblem: "Er is een fout opgetreden bij de groep waar u lid van probeert te worden. Klik nogmaals op de link in de e-mail.",
            ifTheProblemPersists: "Als het probleem aanhoudt, neem dan contact op met de groepsbeheerder of",
            pleaseClickEmailLink: "Klik op de link die naar uw e-mail is gestuurd om lid te worden van de groep."
        },
        modalElements: {
            title: "Word lid van groep",
            accept: "Doe mee",
            cancel: "Uitloggen"
        },
        toast: {
            joined: "Aangesloten",
            successfully: "succesvol",
        }
    },
    viewEditGroup: {
        editGroup: "Groep bewerken",
        labels: {
            groupName: "Groepsnaam",
            members: "Leden",
            devices: "Apparaten"
        },
        buttons: {
            save: "Opslaan",
            cancel: "Annuleren",
            invite: "Uitnodigen",
            leaveGroup: "Groep verlaten",
            deleteGroup: "Groep verwijderen",
        },
        tabs: {
            overview: "Overzicht",
            members: "Leden",
            devices: "Apparaten",
            dashboards: "Dashboards",
            subscription: "Abonnement"
        },
        toasts: {
            updated: "Groep bijgewerkt.",
            deleted: "Groep verwijderd.",
            left: "Linkse groep.",
        },
        modals: {
            leaveModal: {
                title: "Groep verlaten",
                body: {
                    phrase1: ", weet je zeker dat je ",
                    phrase2: " wilt verlaten?",
                },
                buttons: {
                    accept: "Groep verlaten",
                    cancel: "Annuleren",
                }
            },
            deleteModal: {
                title: "Groep verwijderen",
                body: {
                    phrase1: "Weet je zeker dat je ",
                    phrase2: <> wilt <b>verwijderen</b>? Deze actie kan niet ongedaan worden gemaakt.</>,
                },
                buttons: {
                    accept: "Groep verwijderen",
                    cancel: "Annuleren",
                },
                labels: {
                    password: "Wachtwoord",
                },
            }
        }
    },
    memberTable: {
        members: "Leden",
        tableElements: {
            headers: {
                userName: "gebruikersnaam",
                memberID: "Lid ID",
                joinDate: "Datum toetreding"
            },
            quickEdit: "Snelle bewerking",
            searchAllColumns: "Doorzoek alle kolommen",
            goToPage: "Ga naar de pagina",
            show: "Toon",
            loading: "Laden",
            noMembersFound: "Geen leden gevonden"
        },
        toast: {
            cantEditOwnPerms: "U kunt uw eigen rechten niet bewerken"
        }
    },
    inviteTable: {
        outstandingInvitations: "Onbeantwoorde uitnodigingen",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Uitgenodigd door",
                inviteDate: "Uitnodigingsdatum",
                cancelInvitation: "Uitnodiging annuleren"
            },
            noInvitesFound: "Geen uitnodigingen gevonden"
        },
        toast: {
            cancelSuccess: "Uitnodiging succesvol geannuleerd.",
            cancelError: "Probleem met het annuleren van de uitnodiging. Probeer het later nog eens.",
            updateSuccess: "Uitnodiging succesvol bijgewerkt.",
            updateError: "Probleem met het bijwerken van de uitnodiging. Probeer het later nog eens."
        },
        modals: {
            cancelInvite: {
                title: "Uitnodiging annuleren",
                body: {
                    phrase1: "Weet je zeker dat je de uitnodigingen voor ",
                    thisUser: "deze gebruiker",
                    phrase2: " wilt annuleren? Dit zal alle uitnodigingen voor ",
                    phrase3: " verwijderen.",
                },
                buttons: {
                    accept: "Annuleer uitnodiging(en)",
                    cancel: "Annuleren"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Gebruikersrechten bijgewerkt",
            removed: "Gebruiker verwijderd uit groep"
        },
        modals: {
            removeUser: {
                title: "Gebruiker verwijderen",
                body: {
                    phrase1: "",
                    thisUser: "deze gebruiker",
                    phrase2: "verwijderen uit",
                    thisGroup: "deze groep",
                    phrase3: "?"
                },
                buttons: {
                    accept: "Verwijderen",
                    cancel: "Annuleren"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Eigendom overdragen aan",
                    phrase2: "",
                },
                body: {
                    phrase1: "Eigendom van",
                    thisGroup: "deze groep",
                    phrase2: "overdragen aan",
                    thisUser: "deze gebruiker",
                    phrase3: "? U bent niet langer eigenaar van de groep. Account wachtwoord vereist om verder te gaan."
                },
                labels: {
                    password: "Wachtwoord"
                },
                buttons: {
                    accept: "Overdragen",
                    cancel: "Annuleren"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Bewerk lid",
                    thisUser: "deze gebruiker",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Promoveer",
                        thisUser: "deze gebruiker",
                        phrase2: "tot groepseigenaar"
                    },
                    remove: "Verwijder",
                    accept: "Opslaan",
                    cancel: "Annuleren"
                }
            }
        }
    }
};
export const Spanish: LanguageText = {
    createGroup: {
        createAGroup: "Crear un grupo",
        createGroup: "Crear grupo",
        labels: {
            groupName: "Nombre del grupo",
        },
        tooltips: {
            groupName: "Rellene automáticamente este valor haciendo clic en el enlace del correo electrónico que ha recibido"
        },
        toasts: {
            createdSuccessfully: "Grupo creado con éxito",
            creationError: "Se ha producido un error al crear el grupo",
        },
        confirm: {
            title: "Crear grupo",
            phrase1: "¿Estás seguro, ",
            phrase2: ", de que quieres crear un grupo llamado ",
            phrase3: "?",
            buttons: {
                create: "Crear",
                cancel: "Cancelar",
            }
        }
    },
    groupInvite: {
        inviteUser: "Invitar usuario",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Miembro",
            invite: "Invitar",
            cancel: "Cancelar"
        },
        toasts: {
            invited: "Usuario invitado con éxito",
        }
    },
    groupTable: {
        groups: "Grupos",
        groupName: "Nombre del grupo",
        tableElements: {
            quickEdit: "Edición rápida",
            searchAllColumns: "Buscar en todas las columnas",
            goToPage: "Ir a la página",
            show: "Mostrar",
            noGroupsFound: "No se han encontrado grupos",
        }
    },
    joinGroup: {
        phrase1: "¿Estás seguro de que tú, ",
        phrase2: ", quieres unirte al ",
        phrase3: "?",
        error: {
            thereWasAProblem: "Se ha producido un error con el grupo al que intentas unirte. Vuelve a hacer clic en el enlace del correo electrónico",
            ifTheProblemPersists: "Si el problema persiste, póngase en contacto con el administrador del grupo o",
            pleaseClickEmailLink: "Haga clic en el enlace enviado a su correo electrónico para unirse al grupo",
        },
        modalElements: {
            title: "Unirse al grupo",
            accept: "Unirse",
            cancel: "Cerrar sesión"
        },
        toast: {
            joined: "Se unió",
            successfully: "con éxito",
        }
    },
    viewEditGroup: {
        editGroup: "Editar Grupo",
        labels: {
            groupName: "Nombre del grupo",
            members: "Miembros",
            devices: "Dispositivos"
        },
        buttons: {
            save: "Guardar",
            cancel: "Cancelar",
            invite: "Invitar",
            leaveGroup: "Dejar Grupo",
            deleteGroup: "Borrar Grupo",
        },
        tabs: {
            overview: "Visión de conjunto",
            members: "Miembros",
            devices: "Dispositivos",
            dashboards: "Cuadros de mando",
            subscription: "Suscripción",
        },
        toasts: {
            updated: "Grupo actualizado",
            deleted: "Grupo eliminado",
            left: "Grupo de izquierda",
        },
        modals: {
            leaveModal: {
                title: "Dejar Grupo",
                body: {
                    phrase1: ", ¿estás seguro de que quieres dejar el ",
                    phrase2: "?",
                },
                buttons: {
                    accept: "Dejar Grupo",
                    cancel: "Cancelar",
                }
            },
            deleteModal: {
                title: "Borrar Grupo",
                body: {
                    phrase1: <>¿Estás seguro de que quieres <b>borrar</b> </>,
                    phrase2: "? Esta acción no se puede deshacer.",
                },
                buttons: {
                    accept: "Borrar Grupo",
                    cancel: "Cancelar",
                },
                labels: {
                    password: "Contraseña",
                },
            }
        }
    },
    memberTable: {
        members: "Miembros",
        tableElements: {
            headers: {
                userName: "nombre de usuario",
                memberID: "ID de miembro",
                joinDate: "Join Date"
            },
            quickEdit: "Edición rápida",
            searchAllColumns: "Buscar en todas las columnas",
            goToPage: "Ir a la página",
            show: "Mostrar",
            loading: "Cargando",
            noMembersFound: "No se han encontrado miembros"
        },
        toast: {
            cantEditOwnPerms: "No puedes editar tus propios permisos"
        }
    },
    inviteTable: {
        outstandingInvitations: "Invitaciones sin respuesta",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Invitado por",
                inviteDate: "Fecha de invitación",
                cancelInvitation: "Cancelar invitación"
            },
            noInvitesFound: "No se han encontrado invitaciones"
        },
        toast: {
            cancelSuccess: "Invitación cancelada con éxito",
            cancelError: "Problema cancelando invitación. Vuelva a intentarlo más tarde",
            updateSuccess: "Invitación actualizada con éxito",
            updateError: "Problema actualizando invitación. Vuelva a intentarlo más tarde"
        },
        modals: {
            cancelInvite: {
                title: "Cancelar invitación",
                body: {
                    phrase1: "¿Estás seguro de que quieres cancelar las invitaciones a ",
                    thisUser: "este usuario",
                    phrase2: "? Esto eliminará todas las invitaciones a ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Cancelar invitación(es)",
                    cancel: "Cancelar"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Permisos de usuario actualizados",
            removed: "Usuario eliminado del grupo"
        },
        modals: {
            removeUser: {
                title: "Eliminar usuario",
                body: {
                    phrase1: "¿Quitar a",
                    thisUser: "este usuario",
                    phrase2: "del",
                    thisGroup: "este grupo",
                    phrase3: "?"
                },
                buttons: {
                    accept: "Eliminar",
                    cancel: "Cancelar"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Transferir propiedad a",
                    phrase2: "",
                },
                body: {
                    phrase1: "¿Transferir la propiedad de",
                    thisGroup: "este grupo",
                    phrase2: "a",
                    thisUser: "este usuario",
                    phrase3: "? Ya no tendrás la propiedad del grupo. Se requiere la contraseña de la cuenta para continuar."
                },
                labels: {
                    password: "Contraseña"
                },
                buttons: {
                    accept: "Transferir",
                    cancel: "Cancelar"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Editar Miembro",
                    thisUser: "este usuario",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Promover a",
                        thisUser: "este usuario",
                        phrase2: "a propietario de grupo"
                    },
                    remove: "Eliminar",
                    accept: "Guadar",
                    cancel: "Cancelar"
                }
            }
        }
    }
};
export const Italian: LanguageText = {
    createGroup: {
        createAGroup: "Crea un gruppo",
        createGroup: "Crea gruppo",
        labels: {
            groupName: "Nome del gruppo",
        },
        tooltips: {
            groupName: "Compilare automaticamente questo valore facendo clic sul link contenuto nell'e-mail ricevuta",
        },
        toasts: {
            createdSuccessfully: "Gruppo creato con successo",
            creationError: "Si è verificato un errore nella creazione del gruppo",
        },
        confirm: {
            title: "Crea gruppo",
            phrase1: "Sei sicuro che tu, ",
            phrase2: ", vuoi creare un gruppo chiamato ",
            phrase3: "?",
            buttons: {
                create: "Creare",
                cancel: "Annulla",
            }
        }
    },
    groupInvite: {
        inviteUser: "Invita utente",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Membro",
            invite: "Invita",
            cancel: "Annulla"
        },
        toasts: {
            invited: "Utente invitato con successo",
        }
    },
    groupTable: {
        groups: "Gruppi",
        groupName: "Nome del gruppo",
        tableElements: {
            quickEdit: "Modifica rapida",
            searchAllColumns: "Cerca in tutte le colonne",
            goToPage: "Vai alla pagina",
            show: "Mostra",
            noGroupsFound: "Nessun gruppo trovato",
        }
    },
    joinGroup: {
        phrase1: "Sei sicuro che tu, ",
        phrase2: ", vuoi unirti al ",
        phrase3: "?",
        error: {
            thereWasAProblem: "Si è verificato un errore nel gruppo a cui si sta cercando di aderire. Si prega di cliccare nuovamente sul link contenuto nell'e-mail",
            ifTheProblemPersists: "Se il problema persiste, contattare l'amministratore del gruppo o",
            pleaseClickEmailLink: "Cliccate sul link inviato alla vostra e-mail per unirvi al gruppo",
        },
        modalElements: {
            title: "Unisciti al gruppo",
            accept: "Partecipa",
            cancel: "Disconnettersi"
        },
        toast: {
            joined: "Iscritto",
            successfully: "con successo",
        }
    },
    viewEditGroup: {
        editGroup: "Modifica gruppo",
        labels: {
            groupName: "Nome del gruppo",
            members: "Membri",
            devices: "Dispositivi"
        },
        buttons: {
            save: "Salva",
            cancel: "Annulla",
            invite: "Invita",
            leaveGroup: "Gruppo di congedo",
            deleteGroup: "Elimina gruppo",
        },
        tabs: {
            overview: "Panoramica",
            members: "Membri",
            devices: "Dispositivi",
            dashboards: "Cruscotti",
            subscription: "Sottoscrizione",
        },
        toasts: {
            updated: "Gruppo aggiornato",
            deleted: "Gruppo cancellato",
            left: "Gruppo di sinistra",
        },
        modals: {
            leaveModal: {
                title: "Gruppo di congedo",
                body: {
                    phrase1: ", sei sicuro di voler lasciare il ",
                    phrase2: "?",
                },
                buttons: {
                    accept: "Gruppo di congedo",
                    cancel: "Annulla",
                }
            },
            deleteModal: {
                title: "Elimina gruppo",
                body: {
                    phrase1: <>Sei sicuro di voler <b>cancellare</b> il </>,
                    phrase2: "? Questa azione non può essere annullata.",
                },
                buttons: {
                    accept: "Elimina gruppo",
                    cancel: "Annulla",
                },
                labels: {
                    password: "Password",
                },
            }
        }
    },
    memberTable: {
        members: "Membri",
        tableElements: {
            headers: {
                userName: "nome utente",
                memberID: "ID membro",
                joinDate: "Data di adesione"
            },
            quickEdit: "Modifica rapida",
            searchAllColumns: "Cerca in tutte le colonne",
            goToPage: "Vai alla pagina",
            show: "Mostra",
            loading: "Caricamento",
            noMembersFound: "Nessun membro trovato"
        },
        toast: {
            cantEditOwnPerms: "Non sei in grado di modificare le tue autorizzazioni",
        }
    },
    inviteTable: {
        outstandingInvitations: "Inviti senza risposta",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Invitato da",
                inviteDate: "Invita Data",
                cancelInvitation: "Annulla invito"
            },
            noInvitesFound: "Nessun invito trovato"
        },
        toast: {
            cancelSuccess: "Invito/i annullato/i con successo",
            cancelError: "Problema di annullamento degli inviti. Si prega di riprovare più tardi",
            updateSuccess: "Autorizzazioni utente aggiornate",
            updateError: "Problema nell'aggiornamento delle autorizzazioni utente. Si prega di riprovare più tardi",
        },
        modals: {
            cancelInvite: {
                title: "Annulla invito",
                body: {
                    phrase1: "Sei sicuro di voler cancellare gli inviti a ",
                    thisUser: "questo utente",
                    phrase2: "? Questo rimuoverà tutti gli inviti a ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Annulla invito/i",
                    cancel: "Annulla"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Autorizzazioni utente aggiornate",
            removed: "Utente rimosso dal gruppo"
        },
        modals: {
            removeUser: {
                title: "Rimuovi utente",
                body: {
                    phrase1: "Rimuovere",
                    thisUser: "questo utente",
                    phrase2: "dal",
                    thisGroup: "questo gruppo",
                    phrase3: "?"
                },
                buttons: {
                    accept: "Rimuovere",
                    cancel: "Annullare"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Trasferire la proprietà a",
                    phrase2: "",
                },
                body: {
                    phrase1: "Trasferire la proprietà di",
                    thisGroup: "questo gruppo",
                    phrase2: "a",
                    thisUser: "questo utente",
                    phrase3: "? L'utente non avrà più la proprietà del gruppo. Per continuare è necessaria la password dell'account."
                },
                labels: {
                    password: "Password di accesso"
                },
                buttons: {
                    accept: "Trasferimento",
                    cancel: "Annulla"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Modifica del membro",
                    thisUser: "questo utente",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Promuovi",
                        thisUser: "questo utente",
                        phrase2: "a proprietario del gruppo",
                    },
                    remove: "Rimuovere",
                    accept: "Salva",
                    cancel: "Annulla"
                }
            }
        }
    }
};
export const Romanian: LanguageText = {
    createGroup: {
        createAGroup: "Creați un grup",
        createGroup: "Creați un grup",
        labels: {
            groupName: "Numele grupului",
        },
        tooltips: {
            groupName: "Completați automat această valoare dând click pe linkul din e-mailul primit"
        },
        toasts: {
            createdSuccessfully: "Grup creat cu succes",
            creationError: "S-a produs o eroare la crearea grupului",
        },
        confirm: {
            title: "Creați un grup",
            phrase1: "Ești sigur că tu, ",
            phrase2: ", dorești să creezi un grup numit d?",
            phrase3: "?",
            buttons: {
                create: "Creați",
                cancel: "Anulează",
            }
        }
    },
    groupInvite: {
        inviteUser: "Invitați un utilizator",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Membru",
            invite: "Invitați",
            cancel: "Anulează"
        },
        toasts: {
            invited: "Utilizator invitat cu succes",
        }
    },
    groupTable: {
        groups: "Grupuri",
        groupName: "Numele grupului",
        tableElements: {
            quickEdit: "Editare rapidă",
            searchAllColumns: "Căutați toate coloanele",
            goToPage: "Mergi la pagina",
            show: "Arată",
            noGroupsFound: "Nu s-au găsit grupuri",
        }
    },
    joinGroup: {
        phrase1: "Ești sigur că tu, ",
        phrase2: ", dorești să te alături ",
        phrase3: "?",
        error: {
            thereWasAProblem: "S-a produs o eroare cu grupul la care încercați să vă alăturați. Vă rugăm să faceți din nou clic pe link-ul din e-mail.",
            ifTheProblemPersists: "Dacă problema persistă, vă rugăm să contactați administratorul grupului sau",
            pleaseClickEmailLink: "Vă rugăm să dați click pe linkul trimis pe e-mail pentru a vă alătura grupului."
        },
        modalElements: {
            title: "Să se alăture grupului",
            accept: "Aderă",
            cancel: "Deconectați-vă"
        },
        toast: {
            joined: "S-a alăturat",
            successfully: "cu succes",
        }
    },
    viewEditGroup: {
        editGroup: "Editare grup",
        labels: {
            groupName: "Numele grupului",
            members: "Membrii",
            devices: "Dispozitive"
        },
        buttons: {
            save: "Salvați",
            cancel: "Anulează",
            invite: "Invitați",
            leaveGroup: "Părăsiți grupul",
            deleteGroup: "Șterge grupul",
        },
        tabs: {
            overview: "Prezentare generală",
            members: "Membrii",
            devices: "Dispozitive",
            dashboards: "Tablouri de bord",
            subscription: "Abonament",
        },
        toasts: {
            updated: "Grup actualizat.",
            deleted: "Grup șters.",
            left: "Grupul de stânga.",
        },
        modals: {
            leaveModal: {
                title: "Părăsiți grupul",
                body: {
                    phrase1: ", ești sigur că dorești să părăsești ",
                    phrase2: "?",
                },
                buttons: {
                    accept: "Părăsiți grupul",
                    cancel: "Anulează",
                }
            },
            deleteModal: {
                title: "Șterge grupul",
                body: {
                    phrase1: <>Sunteți sigur că doriți să <b>ștergeți</b> </>,
                    phrase2: "? Această acțiune nu poate fi anulată.",
                },
                buttons: {
                    accept: "Șterge grupul",
                    cancel: "Anulează",
                },
                labels: {
                    password: "Parola",
                },
            }
        }
    },
    memberTable: {
        members: "Membrii",
        tableElements: {
            headers: {
                userName: "nume de utilizator",
                memberID: "ID membru",
                joinDate: "Data aderării"
            },
            quickEdit: "Editare rapidă",
            searchAllColumns: "Căutați toate coloanele",
            goToPage: "Mergi la pagină",
            show: "Arată",
            loading: "Încărcare",
            noMembersFound: "Nu s-au găsit membri"
        },
        toast: {
            cantEditOwnPerms: "Nu puteți să vă editați propriile permisiuni"
        }
    },
    inviteTable: {
        outstandingInvitations: "Invitații fără răspuns",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Invitat de",
                inviteDate: "Invitați data",
                cancelInvitation: "Anulează invitația"
            },
            noInvitesFound: "Nu s-au găsit invitații"
        },
        toast: {
            cancelSuccess: "Invitația a fost anulată cu succes.",
            cancelError: "Problemă la anularea invitației. Vă rugăm să încercați din nou mai târziu.",
            updateSuccess: "Permisiunile utilizatorului au fost actualizate cu succes.",
            updateError: "Problemă la actualizarea permisiunilor utilizatorului. Vă rugăm să încercați din nou mai târziu."
        },
        modals: {
            cancelInvite: {
                title: "Anulează invitația",
                body: {
                    phrase1: "Ești sigur că vrei să anulezi invitațiile la ",
                    thisUser: "acest utilizator",
                    phrase2: "? Acest lucru va elimina toate invitațiile la ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Anulează invitația(ele)",
                    cancel: "Anulează"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Permisiuni de utilizator actualizate",
            removed: "Utilizator eliminat din grup"
        },
        modals: {
            removeUser: {
                title: "Înlăturați utilizatorul",
                body: {
                    phrase1: "Eliminați ",
                    thisUser: "acest utilizator",
                    phrase2: "din",
                    thisGroup: "acest grup",
                    phrase3: "?",
                },
                buttons: {
                    accept: "Înlătură",
                    cancel: "Anulează"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Transfer de proprietate către",
                    phrase2: "",
                },
                body: {
                    phrase1: "Transferați proprietatea",
                    thisGroup: "acestui grup",
                    phrase2: "către",
                    thisUser: "acest utilizator",
                    phrase3: "? Nu veți mai avea proprietatea asupra grupului. Parola contului este necesară pentru a continua."
                },
                labels: {
                    password: "Parola"
                },
                buttons: {
                    accept: "Transfer",
                    cancel: "Anulați"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Editare membru",
                    thisUser: "Acest utilizator",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Promovați-l pe",
                        thisUser: "acest utilizator",
                        phrase2: "ca proprietar de grup",
                    },
                    remove: "Eliminați",
                    accept: "Salvați",
                    cancel: "Anulează"
                }
            }
        }
    }
};
export const Portuguese: LanguageText = {
    createGroup: {
        createAGroup: "Criar um grupo",
        createGroup: "Criar Grupo",
        labels: {
            groupName: "Nome do grupo",
        },
        tooltips: {
            groupName: "Preencha automaticamente este valor clicando no link no e-mail que recebeu",
        },
        toasts: {
            createdSuccessfully: "Grupo criado com sucesso",
            creationError: "Houve um erro ao criar o grupo",
        },
        confirm: {
            title: "Criar Grupo",
            phrase1: "Tem a certeza que você, ",
            phrase2: ", gostaria de criar um grupo chamado ",
            phrase3: "?",
            buttons: {
                create: "Criar",
                cancel: "Cancelar",
            }
        }
    },
    groupInvite: {
        inviteUser: "Convidar Utilizador",
        email: "Email",
        buttons: {
            admin: "Admin",
            member: "Membro",
            invite: "Convidar",
            cancel: "Cancel"
        },
        toasts: {
            invited: "Utilizador convidado com sucesso",
        }
    },
    groupTable: {
        groups: "Grupos",
        groupName: "Nome do grupo",
        tableElements: {
            quickEdit: "Edição rápida",
            searchAllColumns: "Pesquisar todas as Colunas",
            goToPage: "Ir para a página",
            show: "Mostrar",
            noGroupsFound: "Não foram encontrados grupos",
        }
    },
    joinGroup: {
        phrase1: "Tem a certeza de que, ",
        phrase2: ", gostaria de se juntar ao ",
        phrase3: "?",
        error: {
            thereWasAProblem: "Houve um erro com o grupo a que se está a tentar juntar. Por favor, clique novamente na ligação no e-mail",
            ifTheProblemPersists: "Se o problema persistir, por favor contacte o administrador do grupo ou",
            pleaseClickEmailLink: "Por favor, clique no link enviado para o seu e-mail para se juntar ao grupo",
        },
        modalElements: {
            title: "Junte-se ao grupo",
            accept: "Junte-se",
            cancel: "Logout"
        },
        toast: {
            joined: "Juntos",
            successfully: "com sucesso",
        }
    },
    viewEditGroup: {
        editGroup: "Grupo de edição",
        labels: {
            groupName: "Nome do grupo",
            members: "Membros",
            devices: "Dispositivos"
        },
        buttons: {
            save: "Salvar",
            cancel: "Cancelar",
            invite: "Convidar",
            leaveGroup: "Deixar o grupo",
            deleteGroup: "Eliminar grupo",
        },
        tabs: {
            overview: "Panorâmica",
            members: "Membros",
            devices: "Dispositivos",
            dashboards: "Painéis de instrumentos",
            subscription: "Subscrição",
        },
        toasts: {
            updated: "Grupo actualizado",
            deleted: "Grupo eliminado",
            left: "Grupo da esquerda",
        },
        modals: {
            leaveModal: {
                title: "Deixar o grupo",
                body: {
                    phrase1: ", tem a certeza de que gostaria de deixar o ",
                    phrase2: "?",
                },
                buttons: {
                    accept: "Deixar o grupo",
                    cancel: "Cancelar",
                }
            },
            deleteModal: {
                title: "Eliminar grupo",
                body: {
                    phrase1: <>Tem a certeza de que quer <b>eliminar</b> </>,
                    phrase2: "? Esta acção não pode ser desfeita.",
                },
                buttons: {
                    accept: "Eliminar grupo",
                    cancel: "Cancelar",
                },
                labels: {
                    password: "Palavra-passe",
                },
            }
        }
    },
    memberTable: {
        members: "Membros",
        tableElements: {
            headers: {
                userName: "nome de utilizador",
                memberID: "Identificação de Membro",
                joinDate: "Data de adesão"
            },
            quickEdit: "Edição rápida",
            searchAllColumns: "Pesquisar todas as colunas",
            goToPage: "Ir para a página",
            show: "Mostrar",
            loading: "Carregamento",
            noMembersFound: "Nenhum membro encontrado"
        },
        toast: {
            cantEditOwnPerms: "Não é capaz de editar as suas próprias permissões"
        }
    },
    inviteTable: {
        outstandingInvitations: "Convites não respondidos",
        tableElements: {
            headers: {
                email: "Email",
                invitedBy: "Convidado por",
                inviteDate: "Data do convite",
                cancelInvitation: "Cancelar convite"
            },
            noInvitesFound: "Não foram encontrados convites"
        },
        toast: {
            cancelSuccess: "Convite cancelado com sucesso",
            cancelError: "Convite de cancelamento do problema. Por favor, tente novamente mais tarde",
            updateSuccess: "Permissões de utilizador actualizadas com sucesso",
            updateError: "Houve um problema ao actualizar as permissões do utilizador. Por favor, tente novamente mais tarde"
        },
        modals: {
            cancelInvite: {
                title: "Cancelar convite",
                body: {
                    phrase1: "Tem a certeza de que quer cancelar os convites para ",
                    thisUser: "este utilizador",
                    phrase2: "? Isto irá retirar todos os convites para ",
                    phrase3: ".",
                },
                buttons: {
                    accept: "Cancelar convite(s)",
                    cancel: "Cancelar"
                }
            }
        }
    },
    groupEditUser: {
        toasts: {
            permsEdited: "Permissões de utilização actualizadas",
            removed: "Utilizador retirado do grupo"
        },
        modals: {
            removeUser: {
                title: "Remover Utilizador",
                body: {
                    phrase1: "Retirar",
                    thisUser: "este utilizador",
                    phrase2: "do",
                    thisGroup: "este grupo",
                    phrase3: "?"
                },
                buttons: {
                    accept: "Remover",
                    cancel: "Cancelar"
                }
            },
            transferOwner: {
                title: {
                    phrase1: "Transferência de propriedade para",
                    phrase2: "",
                },
                body: {
                    phrase1: "Transferir a propriedade",
                    thisGroup: "deste grupo",
                    phrase2: "para",
                    thisUser: "este utilizador",
                    phrase3: "? Deixará de ter a propriedade do grupo. Senha de conta necessária para continuar.",
                },
                labels: {
                    password: "Senha"
                },
                buttons: {
                    accept: "Transferência",
                    cancel: "Cancelar"
                }
            },
            editPerms: {
                title: {
                    phrase1: "Membro de edição",
                    thisUser: "este utilizador",
                    phrase2: ""
                },
                buttons: {
                    makeOwner: {
                        phrase1: "Promover",
                        thisUser: "este utilizador",
                        phrase2: "a proprietário de grupo",
                    },
                    remove: "Remover",
                    accept: "Guardar",
                    cancel: "Cancelar"
                }
            }
        }
    }
};
