import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../app/store";

export interface GroupState {
  groups: Groups;
  images: Images;
}

export type Images = {
  [key: string]: GroupImages[];
}

export type Groups = {
  [key: string]: Group;
};

export type Group = {
  id: string;
  users?: { [key: string]: GroupUser };
  devices?: { [key: string]: GroupDevice };
  dashboards?: { [key: string]: GroupDashboard };
  invited_users?: { [key: string]: InvitedUser };
  name: string;
  subscription: GroupSubscription;
  owner: boolean;
};

export type GroupUser = {
  user_id: string;
  permissions: UserPermissions;
  join_date: string;
  invite_date: string;
  join_token: string;
  username: string;
};

export type GroupDevice = {
  device_id: string;
  claim_date: string;
  claimed_by: string;
  updated_date: string;
  updated_by: string;
  device_eui: string;
};

export type GroupDashboard = {
  dashboard_id: string;
  created_date: string;
  created_by: string;
  updated_date: string;
  updated_by: string;
};

export type InvitedUser = {
  invite_date: Date;
  permissions: UserPermissions;
  invited_email: string;
  invited_by: string;
};

export type GroupSubscription = {
  active: boolean;
  start_date: Date;
  renewal_date: Date;
  renewal_period: string;
  price: number;
  plan_name: string;
  plan_id: string;
  stripe_subscription_id: string;
  subscription_id: string;
  perks: PlanPerks;
  status: string;
  invoices: Invoice[];
};

type PlanPerks = {
  devices: number;
  users: number;
  dashboards: number;
  dashboard_elements: number;
  data_retention: DataRetention;
};

type DataRetention = {
  days: number;
  months: number;
  years: number;
};

export type Invoice = {
  invoice_id: string;
  invoice_number: string;
  invoice_date: Date;
  invoice_url: string;
  invoice_amount: number;
  invoice_currency: string;
  invoice_status: string;
  stripe_payment_intent_id: string;
};

export type GroupLimit = {
  id: string;
  owner_id: string;
  users: { [key: string]: GroupLimitUser };
  devices: { [key: string]: GroupLimitDevice };
  dashboards: { [key: string]: GroupLimitDashboard };
  name: string;
  subscription: GroupSubscription;
};

type GroupLimitUser = {
  user_id: string;
  username: string;
};

type GroupLimitDevice = {
  device_id: string;
  device_name: string;
};

type GroupLimitDashboard = {
  dashboard_id: string;
  name: string;
};

export type UserPermissions = {
  can_claim?: boolean;
  can_unclaim?: boolean;
  can_view_devices?: ViewDevicePermissions;
  can_edit_devices?: EditDevicePermissions;
  can_add_dashboards?: boolean;
  can_remove_dashboards?: boolean;
  can_view_dashboards?: ViewDashboardPermissions;
  can_edit_dashboards?: EditDashboardPermissions;
  can_invite_users?: boolean;
  can_remove_users?: boolean;
  can_view_users?: boolean;
  can_edit_users_permissions?: boolean;
  can_edit_group?: boolean;
  can_delete_group?: boolean;
};

type ViewDevicePermissions = {
  can_view_any?: boolean;
  can_view_all?: boolean;
  can_view_devices?: { [key: string]: boolean };
};

type EditDevicePermissions = {
  can_edit_any?: boolean;
  can_edit_all?: boolean;
  can_edit_devices?: { [key: string]: boolean };
};

type ViewDashboardPermissions = {
  can_view_any?: boolean;
  can_view_all?: boolean;
  can_view_dashboards?: { [key: string]: boolean };
};

type EditDashboardPermissions = {
  can_edit_any?: boolean;
  can_edit_all?: boolean;
  can_edit_dashboards?: { [key: string]: boolean };
};

type GroupImages = {
  id: string;
  name: string;
  description: string;
  url: string;
}

const initialState: GroupState = {
  groups: {},
  images: {},
};

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroups: (state: any, action: PayloadAction<Groups>) => {
      _.map(action.payload, (group: Group) => {
        if (!state.groups[group.id]) {
          state.groups[group.id] = group;
        }
      });
    },
    setGroup: (state: any, action: PayloadAction<Group>) => {
      state.groups[action.payload.id] = action.payload;
    },
    clearGroups: (state: any) => {
      state.groups = {};
    },
    leaveGroup: (state: any, action: PayloadAction<string>) => {
      delete state.groups[action.payload];
    },
  },
});

export const { setGroups, setGroup, clearGroups, leaveGroup } =
  groupSlice.actions;

export const selectGroups = (state: RootState) => state.groups;

export default groupSlice.reducer;
