import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/GroupsText";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { LanguageCheck } from "../../utils/LanguageCheck";
import "react-tabs/style/react-tabs.css";
import ViewEditGroup from "./ViewEditGroup";
import { selectGroups } from "../../features/groups/groupSlice";
import { SubscriptionActiveGroupRedirection } from "../../utils/SubscriptionRedirect";

function EditDeviceIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  let { groupid } = useParams();
  const group = useAppSelector(selectGroups).groups[groupid ?? ""];

  useEffect(() => {
    const redirect = SubscriptionActiveGroupRedirection(group);
    if (redirect) {
      navigate(redirect);
    }
  }, [group, navigate]);

  const [tabIndex, setTabIndex] = useState(switchTabFromURL());

  function switchTabFromURL() {
    if (location.pathname.includes("members")) {
      return 1;
    } else if (location.pathname.includes("devices")) {
      return 2;
    } else if (location.pathname.includes("dashboards")) {
      return 3;
    } else if (location.pathname.includes("subscription")) {
      return 4;
    } else {
      return 0;
    }
  }

  const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  return (
    <div className="h-full w-full">
      <Tabs
        forceRenderTabPanel
        /* defaultIndex={switchTabFromURL()} */
        selectedIndex={switchTabFromURL()}
        onSelect={(index) => setTabIndex(index)}
        className="h-full w-full text-black bg-white rounded-lg"
      >
        <TabList className="bg-white rounded border w-full block overflow-x-scroll whitespace-nowrap overflow-y-hidden scrollbar-thin">
          <Tab onClick={() => navigate(".")}>
            {language.viewEditGroup.tabs.overview}
          </Tab>
          <Tab onClick={() => navigate("./members")}>
            {language.viewEditGroup.tabs.members}
          </Tab>
          <Tab onClick={() => navigate("./devices")}>
            {language.viewEditGroup.tabs.devices}
          </Tab>
          <Tab onClick={() => navigate("./dashboards")}>
            {language.viewEditGroup.tabs.dashboards}
          </Tab>
          {group && group.owner && (
            <Tab onClick={() => navigate("./subscription")}>
              {language.viewEditGroup.tabs.subscription}
            </Tab>
          )}
        </TabList>
        <TabPanel>
          <ViewEditGroup />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        {group && group.owner && (
          <TabPanel>
            <Outlet />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}

export default EditDeviceIndex;
