import React, { useEffect, useState } from "react";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { IoMdSettings } from "react-icons/io";
import {
  ColumnDef,
} from "@tanstack/react-table";


import _ from "lodash";
import { GroupDevice } from "../../features/groups/groupSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useGetGroupQuery } from "../../services/groupAPI";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Device } from "../../features/devices/deviceSlice";
import { handleError } from "../../utils/ErrorHandling";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { Table } from "../../utils/Table";


const defaultData: GroupDevice[] = [];
const defaultDevData: Device[] = [];

export default function DeviceTable() {
  const { data: devInfo /* , isSuccess, isLoading */ } = useGetDevicesQuery("");
  const navigate = useNavigate();
  const { groupid } = useParams();
  const {
    data: groupInfo,
    isSuccess,
    isError,
    error,
  } = useGetGroupQuery({ id: groupid ?? "" } ?? skipToken);

  useEffect(() => {
    if (isSuccess) {
      const devices = _.values(groupInfo?.devices);
      setData([...devices]);
    }
    return () => { };
  }, [isSuccess, groupInfo]);

  useEffect(() => {
    if (isError) {
      handleError(error);
      navigate("/group");
    }
  }, [error]);

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));


  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  useEffect(() => {
    if (isSuccess) {
      const devDevices = _.values(devInfo);
      setDevData([...devDevices]);
    }
    return () => { };
  }, [isSuccess, groupInfo]);

  const [data, setData] = React.useState([...defaultData]);
  const [devData, setDevData] = React.useState([...defaultDevData]);
  const [nameMap, setNameMap] = useState<{
    [key: string]: { name: string } | undefined;
  }>({});

  useEffect(() => {
    var tempNameMap: { [key: string]: { name: string } | undefined } = {};
    devData.map((b) => {
      tempNameMap[b.eui] = { name: b.name };
    });
    setNameMap(tempNameMap);
  }, [devData]);

  const columns: ColumnDef<GroupDevice>[] = [
    {
      accessorFn: (row) => row.device_eui,
      id: "DeviceName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title={language.deviceTable.tableElements.quickEdit}
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              navigate(`/device/${row.original?.device_id}`);
            }}
          />
          <div className="ml-2">{nameMap[getValue() as string]?.name}</div>
        </div>
      ),
      header: language.deviceTable.tableElements.headers.deviceName,
    },
    {
      accessorFn: (row) => row.device_eui,
      id: "DeviceID",
      cell: ({ getValue }) => getValue(),
      header: language.deviceTable.tableElements.headers.deviceID,
    },
    {
      accessorFn: (row) => row.claim_date,
      id: "JoinDate",
      cell: ({ row }) =>
        new Date(row.original?.claim_date ?? 0).toLocaleString("en-GB"),
      header: language.deviceTable.tableElements.headers.claimDate,
    },
  ];


  var table: JSX.Element = <></>;

  table = Table(groupInfo?.devices, columns, language.deviceTable.devices, language.deviceTable.tableElements.noDevicesFound, true);

  return (table);
}
