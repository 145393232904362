import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  LayersControl,
  Pane,
  useMapEvents,
  useMap,
} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import L, { DivIcon, LatLngTuple } from "leaflet";

import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { MarkerData, MapData, ChartType } from "..";
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { useAppSelector } from "../../../../app/hooks";
import { selectDevices } from "../../../../features/devices/deviceSlice";
import _ from "lodash";
import { format } from "fecha";
import { DataType, selectData } from "../../../../features/data/dataSlice";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { selectLanguage } from "../../../../features/user/userSlice";
import { LanguageCheck } from "../../../../utils/LanguageCheck";

type Props = {
  ChartData: ChartType;
  updateChart: (data: ChartType) => void;
};

export default function Map({ ChartData, updateChart }: Props) {
  const nodeData = useAppSelector(selectData);
  const { BaseLayer } = LayersControl;
  const [defaultLat, setDefaultLat] = useState(ChartData.chartScale?.defaultLat ?? 0);
  const [defaultLng, setDefaultLng] = useState(ChartData.chartScale?.defaultLng ?? 0);
  const [defaultZoom, setDefaultZoom] = useState(ChartData.chartScale?.defaultZoom ?? 12);
  const [viewMode, setViewMode] = useState(true); //true = map, false = satellite
  let { devices } = useAppSelector(selectDevices);

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  var mapDetails: MapData = {
    defaultLng: defaultLng,
    defaultLat: defaultLat,
    defaultZoom: defaultZoom,
    markers: [],
  };

  const [markerList, setMarkerList] = useState<MarkerData[]>(
    ChartData.chartData ?? []
  );
  const markerRef = useRef(null);
  const [lockDragging, setLockDragging] = useState(false);
  const [seed, setSeed] = useState(1);

  function getMarkerListFromParams() {
    setMarkerList(ChartData.chartData ?? []);
  }

  useEffect(() => {
    getMarkerListFromParams();
  }, [ChartData.chartData]);

  mapDetails.markers = markerList ?? [];

  function MapEvents() {
    const map = useMapEvents({
      move: () => {
        setDefaultLat(map.getCenter().lat);
        setDefaultLng(map.getCenter().lng);
      },
      zoom: () => {
        setDefaultZoom(map.getZoom());
      },
      baselayerchange: (e) => {
        e.name === "Map" ? setViewMode(true) : setViewMode(false);
      },
      load: () => {
        var newMarkerList = _.cloneDeep(markerList);
        newMarkerList.forEach((marker) => {
          marker.selected = false;
        });
        setMarkerList(newMarkerList);
        var newChartData = _.cloneDeep(ChartData);
        newChartData.chartData = newMarkerList;
        updateChart(newChartData);
      },
    });
    return null;
  }

  function getParamFieldFromMarker(m: MarkerData, field: "description" | "unit"): string {
    var returnField = "";
    _.values(devices).filter(d => d.eui === m.deviceID).map((device) => {
      _.entries(device.configuration).filter(c => c[0] === m.phenomena).map((phenomena) => {
        _.values(phenomena[1]).filter(p => p.elemID === m.elemID).map((elem) => {
          field === "description" ? returnField = elem.description : returnField = elem.unit;
        })
      })
    })
    if (field === "unit") {
      if (returnField === "mm/tip") {
        returnField = "mm";
      } else if (returnField === "V") {
        returnField = ""
      }
    }
    return returnField;
  }

  function getDeviceNameFromEUI(eui: string): string {
    var returnField = "";
    _.values(devices).filter(d => d.eui === eui).map((device) => {
      returnField = device.name;
    })
    return returnField;
  }

  function getPopupContent(m: MarkerData, tempLatestValue: string | number | undefined, tempLatestValueTs: Date | undefined) {
    var latestValueTs = tempLatestValueTs?.toString();
    return (
      <>
        {m.deviceID === "new" ? (
          <span>
            No Device Selected
          </span>
        ) : (
          m.phenomena === "new" ? (
            <span>
              {getDeviceNameFromEUI(m.deviceID)} <br />
              No Parameter Selected
            </span>
          ) : (
            tempLatestValue === "?" ? (
              <span>
                {getDeviceNameFromEUI(m.deviceID)} <br />
                <br />
                {getParamFieldFromMarker(m, "description") + ": "} {"No data within time range"} <br />
                {(latestValueTs !== undefined) && (format(new Date(latestValueTs), " DD/MM/YYYY ") + format(new Date(latestValueTs), " HH:mm:ss"))}
              </span>
            ) : (
              <span>
                {getDeviceNameFromEUI(m.deviceID)} <br />
                <br />
                {getParamFieldFromMarker(m, "description") + ": "} {tempLatestValue}{getParamFieldFromMarker(m, "unit")} <br />
                {(latestValueTs !== undefined) && (format(new Date(latestValueTs), " DD/MM/YYYY ") + format(new Date(latestValueTs), " HH:mm:ss"))}
              </span >
            )
          )
        )
        }
      </>
    )
  }

  function getIcon(m: MarkerData, markerLabel: string | undefined) {
    var growthFactor = -5 + 5 * (markerLabel?.length ?? 1);
    var boxwidth = 25 + 10 * (markerLabel?.length ?? 1);
    return (
      L.divIcon({
        className: "custom-icon-marker",
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${boxwidth} 50" class="marker" style="pointer-events: none;">
      <g transform="matrix(1 0 0 1 ${boxwidth * 0.5} 0)">
        <path stroke="#fff" fill="${m.colour}"
          d="M -7.2 34 L 0 48.4 L 8.4 34 H 8.4 C ${13 + growthFactor} 34 ${14 + growthFactor} 33 ${14 + growthFactor} 20 C ${14 + growthFactor} 4 ${13 + growthFactor} 3 0 3 C ${-12 - growthFactor} 3 ${-13 - growthFactor} 4 ${-13 - growthFactor} 20 C ${-13 - growthFactor} 33 ${-12 - growthFactor} 34 -7.2 34"
        />
        <text filter="url(#solid)" x="0" y="27" fill="black" style="stroke-width: 1px; paint-order: stroke; font: bold 20px sans-serif; stroke-linejoin: round; text-anchor: middle; fill: black; stroke: white;" text-anchor="middle">${markerLabel ?? ""}</text>
      </g>
    </svg>`,
        iconSize: [boxwidth, 50],
        iconAnchor: [boxwidth / 2, 50],
        popupAnchor: [1, -44],
        shadowSize: [41, 41],
      })
    )
  }

  const CenterableMarker = ({ center, content, index, icon, selected }: { center: LatLngTuple, content: JSX.Element, index: number, icon: DivIcon, selected: boolean }) => {
    const map = useMap();

    useEffect(() => {
      if (selected) {
        map.setView(center, map.getZoom());
      }
    }, [map, center, selected]);

    return (
      <Marker
        key={index}
        autoPanOnFocus={true}
        draggable={false}
        ref={markerRef}
        icon={icon}
        eventHandlers={{
          moveend(e) {
            const { lat, lng } = e.target.getLatLng();
            var newMarkerList = _.cloneDeep(markerList);
            newMarkerList.forEach((marker) => {
              marker.draggable = false;
            });
            newMarkerList[index].lat = Number(lat.toFixed(5));
            newMarkerList[index].lng = Number(lng.toFixed(5));
            setMarkerList(newMarkerList);
            var newChartData = _.cloneDeep(ChartData);
            newChartData.chartData = newMarkerList;
            updateChart(newChartData);
          },
          click() {
          },
        }}
        position={center}
      >
        <Popup autoPan={false}>
          {content}
        </Popup>
      </Marker>
    );
  };

  const markers = (
    mapDetails.markers.map((m, index) => {
      var tempLatestValue: number | "?" | undefined = undefined;
      var tempLatestValueTs: Date | undefined = undefined;
      var markerLabel: string | "?" | undefined = undefined;
      if (nodeData) {
        if (
          nodeData.data[m.deviceID as keyof DataType] !== undefined
        ) {
          let latestValue =
            nodeData.data[m.deviceID as keyof DataType][
            nodeData.data[m.deviceID as keyof DataType].length - 1
            ];
          if (latestValue.data_points[m.phenomena] !== undefined) {
            tempLatestValue = (!isNaN(Number(latestValue?.data_points?.[m?.phenomena]?.[m?.elemID ?? ""]) * 10) ? (Math.round((Number(latestValue?.data_points?.[m?.phenomena]?.[m?.elemID ?? ""]) * 10)) / 10) : "?");
            markerLabel = tempLatestValue + (m.showUnit ? getParamFieldFromMarker(m, "unit") : "");
            tempLatestValueTs = latestValue.ts;
          }
        }
      }
      if (m.phenomena === "new" || (!tempLatestValue && tempLatestValue !== 0)) {
        tempLatestValue = "?";
      }
      const content = getPopupContent(m, tempLatestValue, tempLatestValueTs)
      const icon = getIcon(m, markerLabel)
      return (
        <CenterableMarker
          key={index}
          center={[m.lat ?? 0, m.lng ?? 0]}
          content={content ?? <></>}
          icon={icon}
          index={index}
          selected={m.selected}
        />
      );
    })
  )

  const LockDraggingButton: React.FC = () => {
    const map = useMap();
    return (
      <button
        className="bg-white pl-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[5rem] left-3 border rounded absolute w-[1.9rem] h-8 z-[700]"
        onClick={() => {
          if (lockDragging) {
            var templat = map.getCenter().lat;
            var templng = map.getCenter().lng;
            var tempzoom = map.getZoom();
            if (templat && templng && (tempzoom || tempzoom === 0)) {
              var newChartData = _.cloneDeep(ChartData);
              newChartData.chartScale = {
                ...newChartData.chartScale,
                y_axis: {
                  min_auto: newChartData.chartScale?.y_axis.min_auto ?? false,
                  max_auto: newChartData.chartScale?.y_axis.max_auto ?? false,
                },
                defaultZoom: tempzoom,
                defaultLat: templat,
                defaultLng: templng
              };
              updateChart(newChartData);
            }
          }
          setSeed(Math.random());
          setLockDragging(!lockDragging);
        }}
      >
        {lockDragging ? (
          <BsFillUnlockFill size={16} />
        ) : (
          <BsFillLockFill size={16} />
        )}{" "}
      </button>
    )
  };

  return (
    <div className="w-full h-full flex flex-col pb-2">
      {ChartData.title.en &&
        <div className="w-full text-center font-semibold text-lg -mt-1">
          {ChartData.title.en ?? ""}
        </div>
      }
      <div className="w-full grow">
        <MapContainer
          key={seed}
          center={[mapDetails.defaultLat, mapDetails.defaultLng]}
          minZoom={0}
          maxZoom={18}
          zoom={mapDetails.defaultZoom}
          scrollWheelZoom={lockDragging}
          doubleClickZoom={lockDragging}
          boxZoom={lockDragging}
          touchZoom={lockDragging}
          tap={lockDragging}
          style={{ height: "100%", width: "100%" }}
          dragging={lockDragging}
        >
          <LayersControl position="topright">
            <LockDraggingButton />
            <BaseLayer checked={viewMode} name="Map">
              <Pane name="map" style={{ zIndex: 0 }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </Pane>
            </BaseLayer>
            <BaseLayer checked={!viewMode} name="Satellite">
              <Pane name="satellite" style={{ zIndex: 0 }}>
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </Pane>
            </BaseLayer>
          </LayersControl>
          {markers}
          <MapEvents />
        </MapContainer>
      </div>
    </div >
  );
}