import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserGroup } from "../../services/userAPI";

export type User = {
  username: string | null;
  email: string | null;
  phone_number: number | null;
  id: string | null;
  groups: { [key: string]: UserGroup };
  email_verified: boolean | null;
  terms_and_conditions_version: string | null;
  settings: UserSettings;
  latest_terms_accepted?: boolean;
  notifications: { [key: string]: Notification };
};

export type UserSettings = {
  language: string;
};


const initialState: User = {
  username: null,
  email: null,
  phone_number: null,
  id: null,
  groups: {},
  email_verified: null,
  settings: { language: localStorage.getItem("language") ?? "english" },
  terms_and_conditions_version: null,
  latest_terms_accepted: false,
  notifications: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: User, action: PayloadAction<User>) => {
      console.log("Setting user", action.payload);
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.phone_number = action.payload.phone_number;
      state.id = action.payload.id;
      state.groups = action.payload.groups;
      state.terms_and_conditions_version = action.payload.terms_and_conditions_version;
      state.settings = action.payload.settings;
      state.email_verified = action.payload.email_verified;
      state.latest_terms_accepted = action.payload.latest_terms_accepted;
      state.notifications = action.payload.notifications;
    },
    clearUser: (state: any) => {
      state.username = null;
      state.email = null;
      state.phoneNumber = null;
      state.id = null;
      state.groups = null;
      state.verifiedEmail = null;
      state.latest_terms_accepted = false;
      state.notifications = null;
    },
    updateUserSettings: (state: User, action: PayloadAction<UserSettings>) => {
      state.settings = action.payload;
    },
    setTermsAndConditionsUpdated: (
      state: User,
      action: PayloadAction<boolean>
    ) => {
      console.log("Setting terms and conditions updated", action.payload);
      state.latest_terms_accepted = action.payload;
    },
  },
});

export const selectUser = (state: RootState) => state.user;
export const selectLanguage = (state: RootState) =>
  state.user.settings.language;

export const {
  setUser,
  clearUser,
  updateUserSettings,
  setTermsAndConditionsUpdated,
} = userSlice.actions;

export default userSlice.reducer;
