import { rankItem } from "@tanstack/match-sorter-utils";
import {
  FilterFn,
  SortingState,
  ColumnFiltersState,
  ColumnDef,
} from "@tanstack/react-table";
import _ from "lodash";
import { useEffect, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dashboard,
} from "../../features/dashboards/dashboardSlice";
import { selectUser } from "../../features/user/userSlice";
import {
  useDeleteDashboardMutation,
  useGetDashboardsQuery,
} from "../../services/dashboardAPI";
import { useAppSelector } from "../../app/hooks";
import { UserGroup } from "../../services/userAPI";
import AcceptModal from "../../components/modals/AcceptModal";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DashboardText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectLanguage } from "../../features/user/userSlice";
import { toast } from "react-toastify";
import { Table } from "../../utils/Table";
import { Button } from "../../components/Button";

const defaultDashData: Dashboard[] = [];

function GroupDashboardTable() {
  const {
    data: dashboardInfo,
    isSuccess,
    isLoading,
  } = useGetDashboardsQuery("");
  const [data, setData] = useState([...defaultDashData]);
  const { groupid } = useParams();
  let navigate = useNavigate();

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));


  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);
  const user = useAppSelector(selectUser);
  const [
    deleteDashboard,
    {
      isLoading: isDeleteDashboardLoading,
      isSuccess: isDeleteDashboardSuccess,
    },
  ] = useDeleteDashboardMutation();
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState(false);
  const [deleteDashboardId, setDeleteDashboardId] = useState("");
  const [deleteDashboardName, setDeleteDashboardName] = useState("");
  const columns: ColumnDef<Dashboard>[] = [
    {
      accessorFn: (row) => row.name,
      id: "dashboardName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title={language.dashboardTable.tableElements.quickEdit}
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              //navigate("../dashboardView", { replace: true, state: row.original });
              if (data) {
                navigate(`/dashboard/${data[row.index].id}`, {
                  state: row.original,
                });
              }
            }}
          />
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: language.dashboardTable.tableElements.headers.dashboardName,
    },
    {
      accessorFn: (row) => row.description,
      id: "dashboardDescription",
      cell: ({ row, getValue }) => getValue(),
      header: language.dashboardTable.tableElements.headers.description,
    },
    {
      accessorFn: (row) =>
        new Date(row?.updated_at ?? 0).toLocaleString("en-GB"),
      id: "lastUpdated",
      cell: ({ row, getValue }) => getValue(),
      header: language.dashboardTable.tableElements.headers.lastUpdated,
    },
  ];

  useEffect(() => {
    if (isDeleteDashboardSuccess) {
      setShowDeleteDashboardModal(false);
      toast.success(language.dashboardTable.toasts.deleted);
    }
  }, [isDeleteDashboardSuccess]);

  useEffect(() => {
    if (isSuccess) {
      const dashboards = _.values(dashboardInfo);
      setData([...dashboards].filter((w) => w.group_id === groupid));
    }
    return () => { };
  }, [isSuccess, dashboardInfo]);

  if (user.id !== null && groupid) {
    if (
      user.groups[groupid as keyof UserGroup].permissions
        .can_remove_dashboards !== undefined &&
      user.groups[groupid as keyof UserGroup].permissions
        .can_remove_dashboards !== false &&
      _.findIndex(columns, function (o) {
        return o.id == "delete";
      }) === -1
    ) {
      columns.push({
        accessorFn: (row) => (
          <div className="w-fit ml-2">
            <Button
              onClick={() => {
                setDeleteDashboardId(row.id);
                setShowDeleteDashboardModal(true);
                setDeleteDashboardName(row.name);
              }}
              label={language.dashboardTable.tableElements.buttons.delete}
              colour="red"
            />
          </div>
        ),
        id: "delete",
        cell: ({ row, getValue }) => getValue(),
        header: language.dashboardTable.tableElements.headers.deleteDashboard,
      });
    }
  }

  const modals = (
    <>
      {showDeleteDashboardModal && (
        <AcceptModal
          onAccept={() => {
            deleteDashboard({ id: deleteDashboardId ?? "" });
          }}
          onCancel={() => {
            setShowDeleteDashboardModal(false);
          }}
          Title={language.dashboardTable.modals.confirmDelete.title}
          Body={
            <>
              {language.dashboardTable.modals.confirmDelete.phrase1}
              {deleteDashboardName ? (
                <b>{deleteDashboardName}</b>
              ) : (
                language.dashboardTable.modals.confirmDelete.thisDashboard
              )}
              {language.dashboardTable.modals.confirmDelete.phrase2}
            </>
          }
          AcceptButton={language.dashboardTable.modals.confirmDelete.buttons.delete}
          AcceptButtonColour={"red"}
          CancelButton={language.dashboardTable.modals.confirmDelete.buttons.cancel}
          loading={isDeleteDashboardLoading}
        />
      )}
    </>
  )

  var table: JSX.Element = <></>;

  table = Table(data, columns, language.dashboardTable.dashboards, language.dashboardTable.tableElements.noDashboardsFound, true, "dashboardName");

  return (
    <>
      {modals}
      {table}
    </>
  );
}

export default GroupDashboardTable;
