import React, { useEffect, useState } from "react";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { IoMdSettings } from "react-icons/io";
import { ColumnDef } from "@tanstack/react-table";
import _ from "lodash";
import {
  GroupDevice,
  Invoice,
  selectGroups,
} from "../../features/groups/groupSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useGetGroupQuery } from "../../services/groupAPI";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Device } from "../../features/devices/deviceSlice";
import { handleError } from "../../utils/ErrorHandling";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/DeviceText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { Table } from "../../utils/Table";

export default function InvoiceTable() {
  const navigate = useNavigate();
  const { groupid } = useParams();
  const group = useAppSelector(selectGroups).groups[groupid ?? ""];
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const columns: ColumnDef<Invoice>[] = [
    {
      accessorFn: (row) => row.invoice_number,
      id: "invoice_number",
      cell: ({ row }) => row.original?.invoice_number ?? "No Invoice Number",
      header: "Invoice Number",
    },
    {
      accessorFn: (row) => row.invoice_date,
      id: "invoice_date",
      cell: ({ row }) =>
        new Date(row.original?.invoice_date ?? 0).toDateString(),
      header: "Invoice Date",
    },
    {
      accessorFn: (row) => row.invoice_amount,
      id: "invoice_amount",
      cell: ({ row }) =>
        (row.original?.invoice_amount / 100).toFixed(2) +
          " " +
          row.original?.invoice_currency ?? "No Invoice Amount",
      header: "Invoice Amount",
    },
    {
      accessorFn: (row) => row.invoice_status,
      id: "invoice_status",
      cell: ({ row }) => row.original?.invoice_status ?? "No Invoice Status",
      header: "Invoice Status",
    },
    {
      accessorFn: (row) => row.invoice_url,
      id: "invoice_url",
      cell: ({ row }) => (
        <a
          href={row.original?.invoice_url}
          target="_blank"
          rel="noreferrer"
          className="bg-rpr-blue text-white px-4 py-2 rounded-md hover:bg-white hover:text-rpr-blue border border-rpr-blue transition whitespace-nowrap"
        >
          View Invoice
        </a>
      ),
      header: "View Invoice",
    },
  ];

  return Table(
    group.subscription.invoices,
    columns,
    "Invoices",
    "No Invoices Found",
    false,
    "invoice_date"
  );
}
