import { useEffect, useState } from "react";
import { useGetGroupsQuery } from "../../services/groupAPI";
import { IoMdSettings } from "react-icons/io";
import {
  ColumnDef,
} from "@tanstack/react-table";
import { useAppSelector } from "../../app/hooks";
import _ from "lodash";
import { Group } from "../../features/groups/groupSlice";
import { useNavigate } from "react-router-dom";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectLanguage } from "../../features/user/userSlice";
import { Table } from "../../utils/Table";

const defaultData: Group[] = [];

export default function GroupTable() {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const { data: groupInfo, isSuccess, isLoading } = useGetGroupsQuery("");
  const [data, setData] = useState([...defaultData]);

  useEffect(() => {
    if (isSuccess) {
      const groups = _.values(groupInfo);
      setData([...groups]);
    }
    return () => { };
  }, [isSuccess, groupInfo]);

  let navigate = useNavigate();

  const columns: ColumnDef<Group>[] = [
    {
      accessorFn: (row) => row.name,
      id: "GroupName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title={language.groupTable.tableElements.quickEdit}
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              //navigate("../groupView", { replace: true, state: row.original });
              if (data) {
                navigate(`/group/${data[row.index].id}`, {
                  state: row.original,
                });
              }
            }}
          />
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: language.groupTable.groupName,
    },
    {
      accessorFn: (row) => row.id,
      id: "GroupID",
      cell: ({ row, getValue }) => getValue(),
      header: "",
    },
  ];

  var table: JSX.Element = <></>;

  table = Table(groupInfo, columns, language.groupTable.groups, language.groupTable.tableElements.noGroupsFound);

  return (table);
}
