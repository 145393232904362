import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../features/user/userSlice";
import { handleError } from "../../utils/ErrorHandling";
import { useGetUserInfoQuery } from "../../services/userAPI";
import { timeStamp } from 'console';
import { lastIndexOf } from 'lodash';


const DropdownNotification = ({ notifications }: DropdownNotificationProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifying, setNotifying] = useState(true);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  let notificationsArray = [];

  if(notifications) {
    for (const [key, value] of Object.entries(notifications)) {
      
      var newTimestamp = new Date(value.timestamp).toDateString();
      var firstIndex = newTimestamp.indexOf(" ");
      var lastIndex = newTimestamp.lastIndexOf(" ");
      var formattedDate = newTimestamp.substring(firstIndex, lastIndex) + ", " + newTimestamp.substring(lastIndex);
      
      var formattedValue = {
        ...value,
        date: formattedDate,
      };
      console.log(formattedValue);
      notificationsArray.push(formattedValue);

    }

    notificationsArray.sort((a,b) => Date.parse(b.timestamp) - Date.parse(a.timestamp)) // sorts notfications by timestamp
  }
  



  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      <Link
        ref={trigger}
        onClick={() => {
          setNotifying(false);
          setDropdownOpen(!dropdownOpen);
        }}
        to="#"
        className="relative hover:text-primary"
      >
        <span
          className={`absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1 ${
            notifying === false ? 'hidden' : 'inline'
          }`}
        >
          <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
        </span>

        <svg className="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
      </Link>

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-2.5 mr-3 flex h-90 w-75 min-w-settings flex-col rounded-md border border-stroke bg-white shadow-lg dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-80 ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <div className="px-4.5 py-3">
          <h4 className="text-sm font-medium text-bodydark2 px-5">Notifications</h4>
          <h2 className="text-xs font-small px-5 text-gray-500">LATEST</h2>
        </div>

        
        <ul className="flex flex-col h-[70vh] overflow-visible overflow-y-scroll px-5">
          
          
          
          {notificationsArray.length === 0 ? "no notifications" : 
            notificationsArray.map((item,i) => (
              <li key={i}>
                <Link
                  className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
                  to="#"
                >
                  <div className="relative flex flex-row gap-3 items-center">
                    <p className="text-sm">
                      {item.content}
                    </p>
                    <div className="group">
                      {/* {item.read ? 
                      <svg className="absolute right-0 top-1" xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 24 24" fill="blue" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                      : <svg className="absolute right-0 top-1" xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                      } */}
                      <span className="group-hover:opacity-100 transition-opacity bg-gray-200 px-1 text-xs text-black rounded-md absolute right-0 opacity-0 m-4 mx-auto">Mark as read</span>
                    </div>
                  </div>

                  <p className="text-xs">{item.date}</p>
                </Link>
              </li>
            ))
          }

          <li>
            <div className="text-xs py-20 px-10 text-gray-500 text-center border-t border-stroke">
          <p>That's all your unread notifications from the last 30 days.</p>
          </div>
          </li>
          
        </ul>

        
      </div>
    </>
  );
};

type DropdownNotificationProps = {
  notifications: Object | null;
};

export default DropdownNotification;
