import { useEffect, useState } from "react";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { IoMdSettings } from "react-icons/io";
import {
  ColumnDef,
  FilterFn,
} from "@tanstack/react-table";

import _ from "lodash";
import { Device } from "../../features/devices/deviceSlice";
import { useNavigate } from "react-router-dom";

import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { Table } from "../../utils/Table";
import { useGetGroupsQuery } from "../../services/groupAPI";

export default function DeviceTable() {
  const { data: devInfo, isSuccess, isLoading } = useGetDevicesQuery("");

  const { data: groupInfo, isSuccess: groupsIsSuccess, isLoading: groupsIsLoading } = useGetGroupsQuery("");
 
  const navigation = useNavigate();
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const columns: ColumnDef<Device>[] = [
    {
      accessorFn: (row) => row.name,
      id: "DeviceName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title="Quick Edit"
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              navigation(`/device/${row.original?.id}`);
            }}
          />
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: language.deviceTable.tableElements.headers.deviceName,
    },
    {
      accessorFn: (row) => row.eui,
      id: "DeviceID",
      cell: ({ row, getValue }) => getValue(),
      header: language.deviceTable.tableElements.headers.deviceID,
    },
    /* {
      accessorFn: (row) => row.lat.toFixed(4) + ", " + row.lng?.toFixed(4),
      id: "DeviceLocation",
      cell: ({ row, getValue }) => getValue(),
      header: language.deviceTable.tableElements.headers.deviceLocation,
    }, */
    {
      accessorFn: (row) => (_.map(Object.values(groupInfo ?? []))).find((group) => group.id === row.group_id)?.name ?? "",
      id: "DeviceGroup",
      cell: ({ row, getValue }) => getValue(),
      header: language.deviceTable.tableElements.headers.group,
    },
    {
      accessorFn: (row) => row.status.online,
      id: "DeviceStatus",
      cell: ({ row, getValue }) =>
        new Date(row.original?.status.last_message_ts ?? 0).getTime() ===
        -62135596800000
          ? "Offline"
          : (Date.parse(
              new Date(row.original?.status.last_message_ts ?? 0).toISOString()
            ) >
            Date.parse(new Date().toISOString()) - 3 * 60 * 60 * 1000
              ? language.deviceTable.tableElements.online
              : language.deviceTable.tableElements.offline) +
            " " +
            new Date(
              row.original?.status.last_message_ts ?? 0
            ).toLocaleString(),
      header: language.deviceTable.tableElements.headers.deviceStatus,
    },
  ];

  let sortedDevices: Device[] = [];
  if (devInfo) {
    sortedDevices = _.sortBy(devInfo, "group_id");
  }

  var table: JSX.Element = <></>;

  table = Table(devInfo, columns, language.deviceTable.devices, language.deviceTable.tableElements.noDevicesFound, false, "DeviceGroup");

  return (table);
}
